.md-editor-toolbar {
    z-index: 9999;
    box-shadow: none;
}
.MuiInputBase-input.Mui-disabled {
    color: #000;
}
.pie-chart .MuiList-root {
    height: 170px;
    overflow-y: scroll;
}